<template lang="pug">
.select-dropdown-custom
    .select-dropdown-custom-selected(
      @click="openSelectbox"
      v-click-outside="closeSelectbox"
      :class="{open: isOpen}"
    )
      span.select-dropdown-custom-name.selected-name {{ selected }}
      Icon(name="icon-select-box-arrow")
    .select-dropdown-custom-options(
      :class="{open: isOpen}"
    )
      .select-dropdown-custom-name(
        @click="sendOptionData(-1)"
      )
        span.option-name {{ this.$t('DateFilter.Type_Field_unSelected') }}
      .select-dropdown-custom-name(
        v-for="option in optionData"
        @click="sendOptionData(option)"
      )
        span.option-name {{ $t('DateFilter.Type_Field_' + option.key) }}
</template>

<script>
// TODO Make Global Component
export default {
  name: 'custom-select-option',
  props: {
    optionData: {
      type: Array,
      required: true
    },
    detailData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isOpen: false,
      selected: this.$t('DateFilter.Type_Field_unSelected')
    }
  },
  mounted () {
    this.selectedValue()
  },
  methods: {
    openSelectbox () {
      this.isOpen = !this.isOpen
    },
    closeSelectbox () {
      this.isOpen = false
    },
    sendOptionData (option) {
      if (option === -1) {
        this.selected = this.$t('DateFilter.Type_Field_unSelected')
      } else {
        this.selected = this.$t('DateFilter.Type_Field_' + option.key)
        this.$emit('inputSelected', option, this.detailData)
      }
    },
    selectedValue () {
      const selectedValue = this.optionData.filter(item => item.value === this.detailData.value)
      if (selectedValue.length > 0) {
        this.selected = this.$t('DateFilter.Type_Field_' + selectedValue[0].key)
      } else {
        this.selected = this.$t('DateFilter.Type_Field_unSelected')
      }
    }
  }
}
</script>

<style scoped lang="scss">

  .select-dropdown-custom {
    position: relative;
    &-selected {
      display: flex;
      align-items: center;
      width: 131px;
      height: 40px;
      cursor: pointer;
      border: 1px solid $color-gray;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      .icon-select-box-arrow {
        margin-right: 10px;
        transition: all 0.5s;
      }
      &.open {
        border: 1px solid $color-success;
        .icon-select-box-arrow {
          transform: rotate(180deg);
        }
      }
    }
    &-options {
      position: absolute;
      display: grid;
      top: 100%;
      left: 0;
      right: 0;
      border: 1px solid $color-success;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-top: 0;
      background: $color-white;
      transition: all 0.5s;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      z-index: 2;
      &.open {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    }
    .select-dropdown-custom-name {
      position: relative;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      transition: all 0.5s;
      user-select: none;
      padding-left: 13px;
      width: 127px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:not(:last-child) {
        border-bottom: 1px solid $color-gray;
      }
      &:first-child {
        border-bottom: none;
      }
    }
    .selected-name {
      font-size: $font-size-small;
      color: $color-light;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .option-name {
      font-size: $font-size-small;
      color: $color-dark;
    }
  }
</style>
