import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,
  state: {
    dateFilterSettings: []
  },
  getters: {
    dateFilterSettings: state => state.dateFilterSettings
  },
  actions: {
    async getDateFilterSettings({ commit, dispatch }) {
      const loadingName = 'getDateFilterSettings'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('User/settings/dates')
        },
        success: result => {
          commit('SET_DATE_FILTER_SETTINGS', result.data.dateSettings)
          return result
        }
      })
      return results
    }
  },
  mutations: {
    SET_DATE_FILTER_SETTINGS(state, settings) {
      state.dateFilterSettings = [...settings]
    }
  }
}
