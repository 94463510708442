import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,
  state: {
    documentSettingList: []
  },
  getters: {
    DocumentSettingList: state => state.documentSettingList
  },
  actions: {
    async getDocumentFilterSettings({ commit }) {
      const loadingName = 'getDocumentFilterSettings'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('User/settings/showdrafts')
        },
        success: result => {
          commit('SET_DOCUMENT_SETTING_LIST', result.data.showDraftsSettings)
          return result
        }
      })
      return results
    }
  },
  mutations: {
    SET_DOCUMENT_SETTING_LIST(state, settings) {
      let documentSettings = Object.keys(settings).map(i => {
        return {
          key: i,
          isChecked: settings[i] === 'True'
        }
      })
      state.documentSettingList = [...documentSettings]
    },
    SET_CHECKED_STATUS(state, settings) {
      const settingData = state.documentSettingList.find(i => i.key === settings.item.key)
      settingData.isChecked = settings.checkStatus
    }
  }
}
