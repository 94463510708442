export default function($t) {
  return [
    {
      name: 'module',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('ExportSettings.table_header_module')
      },
      get title() {
        return $t('ExportSettings.table_header_module')
      }
    },
    {
      name: 'active',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('ExportSettings.table_header_auto_on')
      },
      get title() {
        return $t('ExportSettings.table_header_auto_on')
      }
    },
    {
      name: 'disabled',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('ExportSettings.table_header_auto_off')
      },
      get title() {
        return $t('ExportSettings.table_header_auto_off')
      }
    }
  ]
}
