import Index from '../'
import User from '../user'
import Tenant from '../company-info'
import SettingsDateFilter from '../date-filter'
import ExportSettings from '../export-settings'
import DocumentSettings from '../document-settings'

export default [
  {
    path: '/settings',
    name: 'SettingsUser',
    component: Index,
    redirect: {
      name: 'SettingsUser'
    },
    meta: {
      main_menu: false,
      slug: 'settings',
      tabPermissionKey: 'EmployeePermissionMenu',
      page: 'settings'
    },
    children: [
      {
        path: 'user',
        name: 'SettingsUser',
        component: User
      }
    ]
  },
  {
    meta: {
      main_menu: false,
      slug: 'settingsTenant',
      tabPermissionKey: 'CompanyInfoMenu',
      page: 'settings'
    },
    path: '/settings/tenant',
    name: 'SettingsTenant',
    component: Tenant
  },
  {
    meta: {
      main_menu: false,
      slug: 'settingsDateFilter',
      page: 'settings'
    },
    path: '/settings/date-filter',
    name: 'SettingsDateFilter',
    component: SettingsDateFilter
  },
  {
    meta: {
      main_menu: false,
      slug: 'settingsExport',
      page: 'settings'
    },
    path: '/settings/export-settings',
    name: 'SettingsExport',
    component: ExportSettings
  },
  {
    meta: {
      main_menu: false,
      slug: 'settingsDocuments',
      page: 'settings'
    },
    path: '/settings/document-settings',
    name: 'SettingsDocument',
    component: DocumentSettings
  }
]
