import requestHandler from "@/utils/requestHandler";
import HTTP from "@/utils/http";
import { UserGroups, Users, Stores } from "@/view/pages/Authorization/model";

export default {
  namespaced: true,

  state: {
    UserGroupList: new UserGroups(),
    UserList: new Users(),
    StoreList: new Stores(),
    SearchStoreList: new Stores(),
    permissions: [],
    storePermissionList: [],
    transferRequestStorePermissionList: [],
    allPermissionList: [],
    roleList: [{ roleId: -1 }],
    permittedStoreGroupList: [],
    hasPermissionToAllStores: false,
    hasPermissionToAllTransferRequestStores: false,
    tenantMultiCurrencyIsActive: false,
    tenantMustHaveSingleStoreInSameDespatchActive: false,
    roleArea: null,
    UserInfo: {},
    userLanguage: null,
    approvalLevel: 0,
    pricePermission: [],
    pageHeaderLinks: [
      "SettingsUser",
      "SettingsDateFilter",
      "SettingsExport",
      "SettingsDocument",
      "SettingsTenant",
    ],
    currencyOptions: {
      digitGroupSeparator: ".",
      decimalCharacter: ",",
      decimalCharacterAlternative: ",",
      roundingMethod: "B",
      decimalPlaces: 3,
      emptyInputBehavior: "null",
      modifyValueOnWheel: false,
      selectNumberOnly: true,
      showOnlyNumbersOnFocus: true,
      maximumValue: 100000000000000000,
      styleRules: {
        positive: "positive",
        negative: "negative",
      },
    },
  },

  getters: {
    pageHeaderLinks: (state) => state.pageHeaderLinks,
    userGroupList: (state) => state.UserGroupList.list,
    isUserGroupsEmpty: (state) => state.UserGroupList.length === 0,
    UserList: (state) => state.UserList,
    StoreList: (state) => state.StoreList,
    SearchStoreList: (state) => state.SearchStoreList.list,
    storePermissionList: (state) => state.storePermissionList,
    TransferRequestStorePermissionList: (state) =>
      state.transferRequestStorePermissionList,
    permissions: (state) => state.permissions,
    hasPermissionToAllStores: (state) => state.hasPermissionToAllStores,
    hasPermissionToAllTransferRequestStores: (state) =>
      state.hasPermissionToAllTransferRequestStores,
    allPermissionList: (state) => state.allPermissionList,
    roleId: (state) => state.roleList[0].roleId,
    userApprovalLevel: (state) => state.approvalLevel,
    permittedStoreGroupList: (state) => state.permittedStoreGroupList,
    RoleArea: (state) => state.roleArea,
    UserInfo: (state) => state.UserInfo,
    pricePermission: (state) => state.pricePermission,
    currencyOptions: (state) => state.currencyOptions,
    userLanguage: (state) => state.userLanguage,
    checkPermission: (state) => (permissionKey) =>
      state.permissions.some(
        (permission) => permission.permissionKey === permissionKey
      ),
    checkPricePermission: (state) => (permissionKey) =>
      state.pricePermission.some(
        (permission) => permission.permissionKey === permissionKey
      ),
    tenantMultiCurrencyIsActive: (state) => state.tenantMultiCurrencyIsActive,
    tenantMustHaveSingleStoreInSameDespatchActive: (state) =>
      state.tenantMustHaveSingleStoreInSameDespatchActive,
  },

  actions: {
    async fetchUserGroupList({ commit, dispatch }) {
      const loadingName = "fetchUserGroupList";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get("Role/all?PageSize=999&PageNumber=1");
        },
        success: (result) => {
          commit("SET_USERGROUP_LIST", result.data.roleList);
          return result;
        },
      });
      return results;
    },

    async fetchUserList({ commit, dispatch }) {
      const loadingName = "fetchUserList";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get("User/all?PageSize=999&PageNumber=1");
        },
        success: (result) => {
          commit("SET_USER_LIST", result.data.userList);
          return result;
        },
      });
      return results;
    },

    async fetchUserGroup({ dispatch }, Id) {
      const loadingName = "fetchUserGroup";
      const params = { Id };
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get("Role", { params });
        },
        success: (result) => result,
      });
      return results;
    },

    async fetchUser({ commit, dispatch, rootState }, Id = null) {
      // get user id from localstorage when havent param
      const loadingName = "fetchUser";

      if (!Id) {
        Id = rootState.Auth.User.id;
      }

      const params = { Id };
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get("User", { params });
        },
        success: (result) => {
          commit("SET_DATA", result.data);
          dispatch("fetchUserForPermission");
          return result;
        },
      });
      return results;
    },

    async fetchUserForPermission(
      { getters, commit, dispatch, rootState },
      Id = null
    ) {
      // get user id from localstorage when havent param
      const loadingName = "fetchUser";

      if (!Id) {
        Id = rootState.Auth.User.id;
      }

      const params = { Id };
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get("User", { params });
        },
        success: (result) => {
          commit("SET_USER_PERMISSIONS", result.data.permissionList);
          commit("SET_USER_PRICE_PERMISSION", result.data.pricePermissionList);
          commit("SET_USER_INFO", result.data.user);
          commit("SET_USER_ROLELIST", result.data.roleList);
          commit(
            "SET_USER_STORE_PERMISSION_LIST",
            result.data.storePermissionList
          );
          commit(
            "SET_USER_TRANSFER_STORE_PERMISSION_LIST",
            result.data.transferRequestStorePermissionList
          );
          commit(
            "SET_USER_TENANT_MULTICURRENCY",
            result.data.tenantMultiCurrencyIsActive
          );
          commit(
            "SET_USER_TENANT_SINGLE_STORE_SAME_DESPATCH",
            result.data.mustHaveSingleStoreInSameDespatch
          );
          dispatch("fetchPermissionRoleList", getters.roleId);
          return result;
        },
      });
      return results;
    },

    async fetchPermissionRoleList({ commit }, RoleId) {
      const loadingName = "fetchPermissionRoleList";
      const params = { RoleId };
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get("Permission/role", { params });
        },
        success: (result) => {
          commit("SET_ALL_PERMISSIONS", result.data.permissionCategoryList);
          return result;
        },
      });
      return results;
    },

    async getPermissionRoleList({ commit }, RoleId) {
      const loadingName = "getPermissionRoleList";
      const params = { RoleId };
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get("Permission/rolelist", { params });
        },
        success: (result) => result,
      });
      return results;
    },

    async createUserGroup({ dispatch }, item) {
      const loadingName = "createUserGroup";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post("Role", item);
        },
        success: (result) => result,
      });
      return results;
    },

    async createUser({ dispatch }, item) {
      const loadingName = "createUser";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post("User", item);
        },
        success: (result) => result,
      });
      return results;
    },

    async updateUserGroup({ dispatch }, item) {
      const loadingName = "updateUserGroup";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put("Role", item);
        },
        success: (result) => result,
      });
      return results;
    },

    async updateUser({ dispatch, commit }, item) {
      const loadingName = "updateUser";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put("User", item);
        },
        success: (result) => result,
      });
      return results;
    },

    async updateUserStatus({ commit }, item) {
      const loadingName = "updateUserStatus";
      const payload = {
        id: item.id,
        isActive: !item.isActive,
      };
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put("User/status", payload);
        },
        success: (result) => {
          commit("UPDATE_USER", item);
          return result;
        },
      });
      return results;
    },

    async deleteUserGroup({ dispatch }, Id) {
      const loadingName = "deleteUserGroup";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.delete("Role", {
            params: { Id },
          });
        },
        success: (result) => result,
      });
      return results;
    },

    async fetchStores({ commit, dispatch }) {
      const loadingName = "fetchStores";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get("Store/searchby/name");
        },
        success: (result) => {
          commit("SET_STORE_LIST", result.data.items);
          return result;
        },
      });
      return results;
    },

    async fetchStoreByName(
      { state, commit, dispatch },
      Name,
      ignorePermission
    ) {
      const loadingName = "fetchStoreByName";
      const url = ignorePermission
        ? `Store/searchby/name?Name=${Name},ignorePermission=${ignorePermission}`
        : `Store/searchby/name?Name=${Name}`;
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get(url);
        },
        success: (result) => {
          commit("SET_SEARCH_STORE_LIST", result.data.items);
          return result;
        },
      });
      return results;
    },

    async getPermittedStoreGroup({ state, commit, dispatch }, Name) {
      const loadingName = "getPermittedStoreGroup";
      const url = `StoreGroup/all/permitted`;
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get(url);
        },
        success: (result) => {
          commit("SET_PERMITTED_STORE_GROUP_LIST", result.data);
          return result;
        },
      });
      return results;
    },

    async checkUserRoleName({ commit, dispatch }, params) {
      const loadingName = "checkUserRoleName";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get("Role/check/name", { params });
        },
        success: (result) => result,
      });
      return results;
    },

    async checkUserEmail({ commit, dispatch }, params) {
      const loadingName = "checkUserEmail";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get("User/check/email", { params });
        },
        success: (result) => result,
      });
      return results;
    },

    async setRoleArea({ commit, state }) {
      const loadingName = "setRoleArea";
      const roleId = state.roleList[0].roleId;
      const url = "Permission/role?RoleId=" + roleId;
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get(url);
        },
        success: (result) => {
          commit("SET_ROLE_AREA", result.data.permissionCategoryList);
          return result;
        },
      });
      return results;
    },

    async setDefaultSettings({ dispatch }, payload) {
      const loadingName = "setDefaultSettings";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post("User/settings/set", payload);
        },
        success: (result) => result,
      });
      return results;
    },

    async getDefaultSettings({ commit, dispatch }) {
      const loadingName = "getDefaultSettings";
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get("User/settings/all");
        },
        success: (result) => {
          return result;
        },
      });
      return results;
    },
  },

  mutations: {
    RESET(state) {
      state.permissions = [];
      state.roleList = [{ roleId: -1 }];
      state.permittedStoreGroupList = [];
      state.roleArea = null;
      state.UserInfo = {};
      state.UserGroupList = new UserGroups();
      state.UserList = new Users();
      state.StoreList = new Stores();
    },
    SET_USERGROUP_LIST(state, list) {
      state.UserGroupList.addItems(list);
    },

    SET_USER_INFO(state, data) {
      state.UserInfo = data;
    },

    SET_STORE_LIST(state, list) {
      state.StoreList.addItems(list);
    },

    SET_SEARCH_STORE_LIST(state, list) {
      state.SearchStoreList.addItems(list);
    },

    SET_USER_STORE_PERMISSION_LIST(state, list) {
      state.storePermissionList = list || [];
    },

    SET_USER_TRANSFER_STORE_PERMISSION_LIST(state, list) {
      state.transferRequestStorePermissionList = list || [];
    },

    SET_USER_LIST(state, list) {
      list.map((user) => {
        const value = `${user.firstname.charAt(0)}${user.surname.charAt(
          0
        )}`.toLocaleUpperCase();

        Object.assign(user, {
          shortName: value,
        });
      });
      state.UserList = list;
    },

    SET_USER_PERMISSIONS(state, permissions) {
      state.permissions = permissions || [];
    },
    SET_USER_PRICE_PERMISSION(state, permissions) {
      state.pricePermission = permissions;
    },
    SET_DATA(state, data) {
      state.approvalLevel = data.approvalLevel;
      state.userLanguage = data.user.languageId;
      state.hasPermissionToAllStores = data.user.hasPermissionToAllStores;
      state.hasPermissionToAllTransferRequestStores =
        data.user.hasPermissionToAllTransferRequestStores;
    },
    SET_ALL_PERMISSIONS(state, list) {
      state.allPermissionList = list || [];
    },
    SET_USER_ROLELIST(state, roleList) {
      state.roleList = roleList || [];
    },
    SET_USER_TENANT_MULTICURRENCY(state, tenantMultiCurrencyIsActive) {
      state.tenantMultiCurrencyIsActive = tenantMultiCurrencyIsActive;
    },
    SET_USER_TENANT_SINGLE_STORE_SAME_DESPATCH(
      state,
      tenantMustHaveSingleStoreInSameDespatch
    ) {
      state.tenantMustHaveSingleStoreInSameDespatchActive =
        tenantMustHaveSingleStoreInSameDespatch;
    },
    SET_PERMITTED_STORE_GROUP_LIST(state, data) {
      state.permittedStoreGroupList = data.permittedStoreGroupList || [];
    },

    SET_ROLE_AREA(state, permissionList) {
      const permList = permissionList.find((i) => i.category === 3);
      permList.permissionList.map((i) => {
        if (i.permissionKey === "SuppliersMenu" && i.hasPermissison) {
          i.permissionList.forEach((item) => {
            if (
              item.permissionKey === "DefineSupplierAsLocal" &&
              item.hasPermissison
            ) {
              state.roleArea = "local";
            }
            if (
              item.permissionKey === "DefineSupplierAsGlobal" &&
              item.hasPermissison
            ) {
              state.roleArea = "global";
            }
          });
        }
      });
    },

    UPDATE_USER(state, item) {
      let user = state.UserList.find((element) => element.id === item.id);
      user.isActive = !item.isActive;
    },
  },
};
