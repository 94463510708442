import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,
  state: {
    exportSettingList: []
  },
  getters: {
    exportSettingList: state => state.exportSettingList
  },
  actions: {
    async getExportFilterSettings({ commit }) {
      const loadingName = 'getExportFilterSettings'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('User/settings/showtemplates')
        },
        success: result => {
          commit('SET_EXPORT_FILTER_SETTINGS', result.data.showTemplateSettings)
          return result
        }
      })
      return results
    },
    async setExportSettings({ commit }, payload) {
      const loadingName = 'setExportSettings'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('User/settings/set', payload)
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    SET_EXPORT_FILTER_SETTINGS(state, settings) {
      let exportSettings = Object.keys(settings).map(i => {
        return {
          key: i,
          isChecked: settings[i] === 'True',
          routeName: i.split('_')[1]
        }
      })
      state.exportSettingList = [...exportSettings]
    },
    SET_CHECKED_STATUS(state, settings) {
      const settingData = state.exportSettingList.find(i => i.key === settings.item.key)
      settingData.isChecked = settings.checkStatus
    }
  }
}
